import React, { Fragment } from "react";
import PropTypes from "prop-types";
import FooterImg from '../footerImage';
import FooterImageLeft from "../footerImageLeft";
const Footer = props => {
  const { path } = props;

  return (
    <Fragment>
      {path !== "/" ? (
       <footer className="footer-1 bg-gray-100 py-8 sm:py-12" id={"sayhello"}>
       <div className="container mx-auto px-2">
         <div className="sm:flex sm:flex-wrap sm:-mx-4 md:py-4 mobile-footer-div">
           <div className="px-4 sm:w-1/2 md:w-1/2 xl:w-1/2 mt-2 pt-2 sm:mt-12 sm:pt-12 mobile-footer-div-child">             
             <h1 className="text-xl text-3xl font-bold mb-6 pl-0">Let's Get In Touch</h1> 
             <p className="text-xl font-bold mb-6 pl-0">Interested in working together?
                <br />  We should queue up a chat. I’ll buy the coffee.</p>       
             <p className="mb-2 sm:text-sm md:mobile-text  xl:text-2xl "><i className={"fa fa-id-card fa-2 mailIcon pt-2"}></i>&nbsp;&nbsp;+91-9634082867</p>
             <p className="mb-2 sm:text-sm md:mobile-text xl:text-2xl "><i className={"fa fa-envelope fa-2 mailIcon pt-2"}></i>&nbsp;&nbsp;singhsethi.parminder@gmail.com</p>
             <div className="flex sm:justify-start xl:justify-start pt-2">
                <a href="https://github.com/parmindersinghsethi94/" target="_blank" className="w-8 h-8 border border-2 border-gray-400 rounded-full text-center py-1 ml-0 text-gray-600 social-icon-hover-color">
                 <i className="fab fa-github mailIcon" title="GitHub"></i>
               </a>
               <a href="https://www.facebook.com/parminder.sethi2" target="_blank" className="w-8 h-8 border border-2 border-gray-400 rounded-full text-center py-1 ml-2 text-gray-600 social-icon-hover-color">
                 <i className="fab fa-facebook mailIcon" title="Facebook"></i>
               </a>
               <a href="https://twitter.com/parminderssethi" target="_blank" className="w-8 h-8 border border-2 border-gray-400 rounded-full text-center py-1 ml-2 text-gray-600 social-icon-hover-color">
                 <i className="fab fa-twitter mailIcon" title="Twitter"></i>
               </a>
               <a href="https://www.linkedin.com/in/parmindersinghsethi/" target="_blank" className="w-8 h-8 border border-2 border-gray-400 rounded-full text-center py-1 ml-2 text-gray-600 social-icon-hover-color">
                 <i className="fab fa-linkedin mailIcon" title="LinkedIn"></i>
               </a>
               <a href="https://www.youracclaim.com/users/parminder-singh.40158e9c/badges" target="_blank" className="w-8 h-8 border border-2 border-gray-400 rounded-full text-center py-1 ml-2 text-gray-600 social-icon-hover-color">
                  <i className="fa fa-angle-double-up  mailIcon " title="Acclaim"></i>
               </a>
               <a href="https://uspto.report/company/Sethi-Parminder-Singh" target="_blank" className="w-8 h-8 border border-2 border-gray-400 rounded-full text-center py-1 ml-2 text-gray-600 social-icon-hover-color">
                  <span className="fab mailIcon justia-icon" title="Justia">P</span>
               </a>
               <a href="https://www.researchgate.net/profile/Parminder_Sethi" target="_blank" className="w-8 h-8 border border-2 border-gray-400 rounded-full text-center py-1 ml-2 text-gray-600 social-icon-hover-color">
                  <span className="fab mailIcon justia-icon" title="ResearchGate">R<span className="text-sup">G</span></span>
               </a>
             </div>
             
             {/* <FooterImageLeft /> */}
           </div>
           <div className="px-4 sm:w-1/2 md:w-1/2 xl:w-1/2 mt-2 pt-2 sm:mt-12 sm:pt-12 mt-8 sm:mx-auto xl:mt-0 xl:ml-auto">
             <FooterImg />
           </div>
         </div>
     
         <div className="sm:flex sm:flex-wrap sm:-mx-4 mt-2 pt-2 sm:mt-12 sm:pt-12 border-t">
         <div className="px-4 sm:w-1/2 md:w-1/2 mt-4 md:mt-0">
             {/* <h6 className="font-light mb-2">© 2020 Parminder Singh. All Rights Reserved</h6>              */}
           </div>
         </div>
       </div>
   </footer>
      ) : null}
    </Fragment>
  );
};

Footer.propTypes = {
  html: PropTypes.string,
  path: PropTypes.string.isRequired,
  // theme: PropTypes.object.isRequired
};

export default Footer;
